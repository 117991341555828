/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// jshint ignore: start

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    $(window).load(function(){
        $('#loader').fadeOut(1000);
    });

    $(window).scroll(function() {
        if ($(this).scrollTop() > 1){
            $('header').addClass("sticky");
            $('header .top').slideUp();
        } else {
            $('header').removeClass("sticky");
            $('header .top').slideDown();
        }
    });

    $('nav ul li:not(".current-menu-item")').click(function() {
        $('nav ul li').removeClass('current-menu-item');
    });

    $('.mobileMenu').click(function(e) {
        e.preventDefault();
        $('#menu').toggleClass('active');
    });

    // Snazzy maps
    google.maps.event.addDomListener(window, 'load', init);

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 16,
            scrollwheel: false,
            mapTypeId: 'satellite',

            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(49.152233, 19.130387),

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            // styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(49.152233, 19.130387),
            map: map,
            title: 'Snazzy!'
        });
    }

    // small / med / large
    $("#small").click(function(event){
        event.preventDefault();
        $("h1").animate({"font-size":"24px"});
        $("h2").animate({"font-size":"16px"});
        $("p").animate({"font-size":"12px", "line-height":"16px"});
        $(".sidebar ul li").animate({"font-size":"12px", "line-height":"16px"});
    });

    $("#medium").click(function(event){
        event.preventDefault();
        $("h1").animate({"font-size":"36px"});
        $("h2").animate({"font-size":"24px"});
        $("p").animate({"font-size":"16px", "line-height":"22px"});
        $(".sidebar ul li").animate({"font-size":"16px", "line-height":"22px"});
    });

    $("#large").click(function(event){
        event.preventDefault();
        $("h1").animate({"font-size":"48px"});
        $("h2").animate({"font-size":"30px"});
        $("p").animate({"font-size":"20px", "line-height":"24px"});
        $(".sidebar ul li").animate({"font-size":"20px", "line-height":"24px"});
    });

    $( ".typographySize li a" ).click(function() {
        $("a").removeClass("selected");
        $(this).addClass("selected");
    });

    $('.sidebar .hasChildren .plus').click(function() {
        $(this).parent().find('ul').slideDown(500);
        $(this).hide();
        $(this).parent().find('.minus').css('display', 'inline-block');
    });

    $('.sidebar .hasChildren .minus').click(function() {
        $(this).parent().find('ul').slideUp(500);
        $(this).hide();
        $(this).parent().find('.plus').css('display', 'inline-block');
    });

    // load posts
    jQuery(document).ready(function($) {

        $('.fancybox').fancybox();

        $(window).bind('scroll',function(e){
            parallaxScroll();
        });

        function parallaxScroll(){
            var scrolledY = $(window).scrollTop();
            $('.mc-image').css('background-position','center -'+((scrolledY*0.2))+'px');
        }

        var $mainContent = $("#middleContent"),
            siteUrl = "http://" + top.location.host.toString(),
            url = '';
        $(document).delegate("a[href^='"+siteUrl+"']:not([href*='/wp-admin/']):not([href*='/app/uploads/']):not([href*='/galeria/']):not([href*='/galeria-kategorie/']):not([href*='/wp-login.php']):not([href$='/feed/'])", "click", function() {
            location.hash = this.pathname;
            return false;
        });
        $("#searchform").submit(function(e) {
            e.preventDefault();
            location.hash = '?s=' + $("#s").val();
            var scrollTo = jQuery('#middleContent').offset().top - jQuery('header').height();
            jQuery('html,body').animate({
                'scrollTop': scrollTo
            }, 500);
        });
        $(window).bind('hashchange', function(){
            url = window.location.hash.substring(1);
            if (!url) {
                return;
            }
            url = url + " .postWrapper";
            $mainContent.animate({opacity: "0.1"}).html('<p>Loading...</p>').load(url, function() {
                $('.gallery a').fancybox();
                $mainContent.animate({opacity: "1"});
                var scrollTo = jQuery('#middleContent').offset().top - jQuery('header').height();
                jQuery('html,body').animate({
                    'scrollTop': scrollTo
                }, 500);
            });
        });

        $(window).trigger('hashchange');

        // carousel
        $('.carousel').carousel({
            interval: 10000
        });

        $('.control-box').click(function(e) {
            e.preventDefault();
        });

        // scroll to the cat navigation menu if top cat URLs
        var currentUrl = window.location.pathname;
        if (currentUrl == "/obec/" || currentUrl == "/aktuality/" || currentUrl == "/samosprava/" || currentUrl == "/obcan/" || currentUrl == "/zivot-v-obci/") {
            var scrollTo = jQuery('.catMenu').offset().top - jQuery('header').height();
            jQuery('html,body').animate({
                'scrollTop': scrollTo
            }, 500);
        }

        if (currentUrl.toLowerCase().indexOf("/galeria") >= 0) {
            var scrollTo = jQuery('.gallery').offset().top - jQuery('header').height();
            jQuery('html,body').animate({
                'scrollTop': scrollTo
            }, 0);
        }

    });

    $('nav ul li a[href*=#]').click(function (e) {
        e.preventDefault();

        $('nav').removeClass('active');

        var id = $(this).attr('href');
        var scrollTo = $(id).offset().top;

        $('html,body').animate({
            'scrollTop': scrollTo-$('header').height()
        }, 500);
    });

    $('nav ul li:first-child').click(function (e) {
        e.preventDefault();

        $('nav').removeClass('active');

        $('html,body').animate({
            'scrollTop': 0
        }, 500);
    });

    $('.menuToggle').click(function() {
        $('nav ul').toggleClass('active');
    });

    // back to top
    var offset = 220;
    var duration = 500;
    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > offset) {
            jQuery('.back-to-top').fadeIn(duration);
        } else {
            jQuery('.back-to-top').fadeOut(duration);
        }
    });

    $('.back-to-top').click(function(event) {
        event.preventDefault();
        jQuery('html, body').animate({scrollTop: 0}, duration);
        return false;
    })

    /**
     * Get all dates for current month
     */

    function printDateNumber(monthNumber, mon, tue, wed, thur, fri, sat, sund) {

        $($('tbody.event-calendar tr')).each(function(index) {
            $(this).empty();
        });

        $($('thead.event-days tr')).each(function(index) {
            $(this).empty();
        });

        function getDaysInMonth(month, year) {
            // Since no month has fewer than 28 days
            var date = new Date(year, month, 1);
            var days = [];
            while (date.getMonth() === month) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return days;
        }

        var yearNumber = (new Date).getFullYear();
        i = 0;

        setDaysInOrder(mon, tue, wed, thur, fri, sat, sund);

        function setDaysInOrder(mon, tue, wed, thur, fri, sat, sund) {
            var monthDay = getDaysInMonth(monthNumber - 1, yearNumber)[0].toString().substring(0, 3);
            if (monthDay === 'Mon') {
                $('thead.event-days tr').append('<td>' + mon + '</td><td>' + tue + '</td><td>' + wed + '</td><td>' + thur + '</td><td>' + fri + '</td><td>' + sat + '</td><td>' + sund + '</td>');
            } else if (monthDay === 'Tue') {
                $('thead.event-days tr').append('<td>' + tue + '</td><td>' + wed + '</td><td>' + thur + '</td><td>' + fri + '</td><td>' + sat + '</td><td>' + sund + '</td><td>' + mon + '</td>');
            } else if (monthDay === 'Wed') {
                $('thead.event-days tr').append('<td>' + wed + '</td><td>' + thur + '</td><td>' + fri + '</td><td>' + sat + '</td><td>' + sund + '</td><td>' + mon + '</td><td>' + tue + '</td>');
            } else if (monthDay === 'Thu') {
                $('thead.event-days tr').append('<td>' + thur + '</td><td>' + fri + '</td><td>' + sat + '</td><td>' + sund + '</td><td>' + mon + '</td><td>' + tue + '</td><td>' + wed + '</td>');
            } else if (monthDay === 'Fri') {
                $('thead.event-days tr').append('<td>' + fri + '</td><td>' + sat + '</td><td>' + sund + '</td><td>' + mon + '</td><td>' + tue + '</td><td>' + wed + '</td><td>' + thur + '</td>');
            } else if (monthDay === 'Sat') {
                $('thead.event-days tr').append('<td>' + sat + '</td><td>' + sund + '</td><td>' + mon + '</td><td>' + tue + '</td><td>' + wed + '</td><td>' + thur + '</td><td>' + fri + '</td>');
            } else if (monthDay === 'Sun') {
                $('thead.event-days tr').append('<td>' + sund + '</td><td>' + mon + '</td><td>' + tue + '</td><td>' + wed + '</td><td>' + thur + '</td><td>' + fri + '</td><td>' + sat + '</td>');
            }
        };

        $(getDaysInMonth(monthNumber - 1, yearNumber)).each(function(index) {
            var index = index + 1;
            if (index < 8) {
                $('tbody.event-calendar tr.1').append('<td date-month="' + monthNumber + '" date-day="' + index + '">' + index + '</td>');
            } else if (index < 15) {
                $('tbody.event-calendar tr.2').append('<td date-month="' + monthNumber + '" date-day="' + index + '">' + index + '</td>');
            } else if (index < 22) {
                $('tbody.event-calendar tr.3').append('<td date-month="' + monthNumber + '" date-day="' + index + '">' + index + '</td>');
            } else if (index < 29) {
                $('tbody.event-calendar tr.4').append('<td date-month="' + monthNumber + '" date-day="' + index + '">' + index + '</td>');
            } else if (index < 32) {
                $('tbody.event-calendar tr.5').append('<td date-month="' + monthNumber + '" date-day="' + index + '">' + index + '</td>');
            }
            i++;
        });
        var date = new Date();
        var month = date.getMonth() + 1;
        setCurrentDay(month);
        setEvent();
        displayEvent();
    }

    /**
     * Get current day and set as '.current-day'
     */
    function setCurrentDay(month) {
        var viewMonth = $('.month').attr('data-month');
        if (parseInt(month) === parseInt(viewMonth)) {
            $('tbody.event-calendar td[date-day="' + d.getDate() + '"]').addClass('current-day');
        }
    };

    /**
     * Add class '.active' on calendar date
     */
    $('tbody td').on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('event') && !$(this).hasClass('active')) {
            //$('tbody.event-calendar td').removeClass('active');
            $(this).addClass('active');
        } else {
            $('tbody.event-calendar td').removeClass('active');
            $('.day-event').slideUp('fast');
        };
    });

    /**
     * Add '.event' class to all days that has an event
     */
    function setEvent() {
        $('.day-event').each(function(i) {
            var eventMonth = $(this).attr('date-month');
            var eventDay = $(this).attr('date-day');
            var eventClass = $(this).attr('event-class');
            if (eventClass === undefined) eventClass = 'event';
            else eventClass = 'event ' + eventClass;
            $('tbody.event-calendar tr td[date-month="' + eventMonth + '"][date-day="' + eventDay + '"]').addClass(eventClass);
        });
    };

    /**
     * Get current day on click in calendar
     * and find day-event to display
     */
    function displayEvent() {
        $('tbody.event-calendar td').on('click', function(e) {
            // $('.day-event').slideUp('fast');
            var monthEvent = $(this).attr('date-month');
            var dayEvent = $(this).text();
            $('.day-event[date-month="' + monthEvent + '"][date-day="' + dayEvent + '"]').slideDown('fast');
        });
    };

    /**
     * Close day-event
     **/
    $('.event.active').on('click', function(e) {
        e.preventDefault();
        $('.day-event').slideUp('fast');
    });

    /**
     * Home page background
     */
    $(function(){
        $('#maximage').maximage({
            cycleOptions: {
                fx: 'fade',
                speed: 1000,
                timeout: 5000,
                prev: '#arrow_left',
                next: '#arrow_right',
                pause: 0
            },
            fillElement: '#holder',
            backgroundSize: 'contain'
        });
    });

    /**
     * CatMenu animation add/remove class
     */
    if ($(window).width() > 768) {
        $('.catMenu ul li').hover(
            function(){ $(this).find('img').addClass('flip') },
            function(){ $(this).find('img').removeClass('flip') }
        );

        $('.footerIcons img').hover(
            function(){ $(this).addClass('pulse') },
            function(){ $(this).removeClass('pulse') }
        )
    }

    $(window).scroll(function() {

        if ($(this).scrollTop() < $('.catMenu').position().top-$('header').height()) {
            $('.nav li').removeClass('current-menu-item');
            $('#menu-item-84').addClass('current-menu-item');
        }

        if ( $( ".home .catMenu" ).length ) {
            if ($(this).scrollTop() > $('.home .catMenu').position().top-$('header').height() && $(this).scrollTop() < $('#galeria').position().top-$('header').height()) {
                $('.nav li').removeClass('current-menu-item');
                $('#menu-item-10').addClass('current-menu-item');
            }
        }

        if ($(this).scrollTop() > $('#galeria').position().top-$('header').height() && $(this).scrollTop() < $('#mapa').position().top-$('header').height()) {
            $('.nav li').removeClass('current-menu-item');
            $('#menu-item-11').addClass('current-menu-item');
        }

        if ($(this).scrollTop() > $('#mapa').position().top-$('header').height() && $(this).scrollTop() < $('#napistenam').position().top-$('header').height()) {
            $('.nav li').removeClass('current-menu-item');
            $('#menu-item-12').addClass('current-menu-item');
        }

        if ($(this).scrollTop() > $('#napistenam').position().top-$('header').height() && $(this).scrollTop() < $('#kontakt').position().top-$('header').height()) {
            $('.nav li').removeClass('current-menu-item');
            $('#menu-item-13').addClass('current-menu-item');
        }

        if ($(this).scrollTop() > $('#kontakt').position().top-$('header').height()) {
            $('.nav li').removeClass('current-menu-item');
            $('#menu-item-14').addClass('current-menu-item');
        }

    });


})(jQuery); // Fully reference jQuery after this point.
